import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import { cases, expert } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ButtonTypeOne from '../../includes/buttons/ButtonTypeOne'
import DateTimeSlot from '../session_summary/modals/DateTimeSlot'

const FollowUp = ({
  isFollowUp,
  setIsFollowUp,
  activeOption,
  setActiveOption,
  currentSession,
  caseDetails,
}) => {
  const { id } = useParams()
  const { state, dispatch } = useContext(Context)
  const access_token = state.user_details.access_token
  const [showErrors, setShowErrors] = useState(false)
  const [FollowupDate, setFollowupDate] = useState('')
  let today = new Date()

  const [selectedNewDate, setSelectedNewDate] = useState(new Date())
  const [selectedDateObject, setSelectedDateObject] = useState({})
  const [datesListArray, setDatesListArray] = useState([])

  const [selectedTime, setSelectedTime] = useState({})
  const [selectedDate, setSelectedDate] = useState({})
  const [timeSlots, setTimeSlots] = useState({})
  const [filteredTimeSlots, setFilteredTimeSlots] = []
  const [timeSlot, setTimeSlot] = useState({})

  const [insight, setInsight] = useState('')
  const handleToggle = () => {
    setIsFollowUp((prev) => !prev)
  }
  const handlePrevious = () => {
    // setActiveOption(activeOption - 1)
    dispatch({
      type: 'UPDATE_USER',
      user_details: {
        activeOption: 2,
      },
    })
  }
  const navigate = useNavigate()
  const handleCancel = () => {
    navigate(-1)
  }

  // Start the counter from 1

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
  const days_full = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const weekDaysChoices = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  const generateDateObject = () => {
    // Clear the existing datesListArray and reset selectedDateObject
    setDatesListArray([])
    setSelectedDateObject(null)
    let id_no = 0

    const today = new Date() // Today's date

    // Use selectedNewDate as the base date
    const baseDate = selectedNewDate || today // If selectedNewDate is not set, use today

    // Calculate endDate as selectedNewDate + 30 days
    const endDate = new Date(baseDate)
    endDate.setDate(baseDate.getDate() + 30)

    // Generate selectedDateObject
    const selectedDateObject = {
      id: id_no++,
      exact_date: moment(baseDate).format('YYYY-MM-DD'),
      date_full: new Date(baseDate),
      date: baseDate.getDate(),
      day_name: days[baseDate.getDay()],
      day_full: days_full[baseDate.getDay()],
      month: baseDate.getMonth(),
      month_name: months[baseDate.getMonth()],
      week_day:
        days[baseDate.getDay()] == 'Mon'
          ? 0
          : days[baseDate.getDay()] == 'Tue'
          ? 1
          : days[baseDate.getDay()] == 'Wed'
          ? 2
          : days[baseDate.getDay()] == 'Thu'
          ? 3
          : days[baseDate.getDay()] == 'Fri'
          ? 4
          : days[baseDate.getDay()] == 'Sat'
          ? 5
          : days[baseDate.getDay()] == 'Sun'
          ? 6
          : null,
      year: baseDate.getFullYear(),
    }

    // Set the selectedDateObject
    setSelectedDateObject(selectedDateObject)

    // Generate datesListArray for the date range
    let dateRange = Math.ceil((endDate - today) / (24 * 60 * 60 * 1000)) // Calculate the number of days
    if (dateRange > 40) {
      dateRange = 40
    } else if (dateRange < 30) {
      dateRange = 30
    }
    // console.log(dateRange, "dateRange");
    for (let i = 0; i < dateRange; i++) {
      const currentDate = new Date(today)
      currentDate.setDate(today.getDate() + i)

      const dateObject = {
        id: id_no++,
        exact_date: moment(currentDate).format('YYYY-MM-DD'),
        date_full: new Date(currentDate),
        date: currentDate.getDate(),
        day: days[currentDate.getDay()],
        day_full: days_full[currentDate.getDay()],
        month: currentDate.getMonth(),
        month_name: months[baseDate.getMonth()],
        week_day:
          days[currentDate.getDay()] == 'Mon'
            ? 0
            : days[currentDate.getDay()] == 'Tue'
            ? 1
            : days[currentDate.getDay()] == 'Wed'
            ? 2
            : days[currentDate.getDay()] == 'Thu'
            ? 3
            : days[currentDate.getDay()] == 'Fri'
            ? 4
            : days[currentDate.getDay()] == 'Sat'
            ? 5
            : days[currentDate.getDay()] == 'Sun'
            ? 6
            : null,
        year: currentDate.getFullYear(),
      }

      // Update the datesListArray using the setDatesListArray function
      setDatesListArray((prevArray) => [...prevArray, dateObject])
    }
  }

  const handleNext = () => {
    setActiveOption(activeOption + 1)
  }

  const getUpdatedTimeSlots = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    expert
      .get(`/get-updated-time-slots/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const gettimeSlots = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    expert
      .get(`/default-time-slots/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        params: {
          week_day: selectedDateObject?.week_day,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setTimeSlots(data)

          setSelectedTime({})

          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }

  const getDaySlots = () => {
    // setLoading(true);
    let accessToken = state.user_details.access_token
    expert
      .get(`/sessions/day-slots/`, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        params: {
          day: selectedDateObject?.date,
          month: selectedDateObject?.month,
          year: selectedDateObject?.year,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setTimeSlots(data.slots)

          setSelectedTime({})

          // setTimeout(() => {}, 50);
        } else {
          // setLoading(false);
        }
      })
      .catch((error) => {
        alert(error.response.data.data.message)
      })
  }
  // console.log(selectedDateObject.exact_date, "selectedDateObject.exact_date");
  const formData = {
    followup_date: isFollowUp ? selectedDateObject.exact_date : '',
    followup_time: selectedTime.id,
    message: insight,
    is_followup: isFollowUp,
  }
  const handleFormSubmit = () => {
    // console.log(formData, "formData");
    if (selectedTime && selectedDate) {
      cases
        .post(`/sessions/create-follow-up/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(function (response) {
          const { StatusCode } = response.data
          getDaySlots()
          setShowErrors('')
          if (StatusCode === 6000) {
            navigate(`/session-single-page/${id}`)
            // alert(response.data.data.message);
            dispatch({
              type: 'UPDATE_USER',
              user_details: {
                activeOption: 1,
              },
            })
          } else {
            alert(response.data.data.message)
            // dispatch({
            //     type: "UPDATE_USER",
            //     user_details: {
            //         activeOption: 1,
            //     },
            // });
          }
        })
        .catch((error) => {
          console.error('Something went wrong', error)
          if (error.response.status === 404) {
            setShowErrors('NOT_FOUND')
          }
        })
    } else {
      setShowErrors('select the slot')
    }
  }

  function detectChanges(initialValues, currentValues) {
    const isObject = (value) => typeof value === 'object' && value !== null

    const deepEqual = (obj1, obj2) => {
      if (!isObject(obj1) || !isObject(obj2)) {
        return obj1 === obj2
      }

      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      return keys1.every((key) => deepEqual(obj1[key], obj2[key]))
    }
    // console.log(
    //     !deepEqual(initialValues, currentValues),
    //     "deep equal result"
    // );
    return !deepEqual(initialValues, currentValues)
  }

  useEffect(() => {
    generateDateObject()
  }, [selectedNewDate])

  useEffect(() => {
    if (
      selectedDateObject.date &&
      selectedDateObject.month &&
      selectedDateObject.year
    ) {
      getDaySlots()
    }
  }, [selectedDateObject])

  return (
    <>
      <Container>
        <TopContainer>
          <Left>
            <FormTitle>Follow Up</FormTitle>
            <Description>Turn on follow-up if the patient needs it</Description>
          </Left>
          <Right>
            <ToggleEvent>
              <ToggleContainer>
                <ToggleLabel>
                  <ToggleInputChecked
                    type="checkbox"
                    checked={isFollowUp}
                    onChange={handleToggle}
                  />
                  <ToggleSlider />
                </ToggleLabel>
              </ToggleContainer>
            </ToggleEvent>
          </Right>
        </TopContainer>

        {isFollowUp && (
          <>
            {/* test */}
            <DateTimeSlot
              selectedTime={selectedTime}
              selectedDate={selectedNewDate}
              // setSelectedNewDate={setSelectedNewDate}
              setSelectedTime={setSelectedTime}
              setSelectedDate={setSelectedNewDate}
              timeSlots={timeSlots}
              setTimeSlot={setTimeSlot}
              caseReport
              datesListArray={datesListArray}
              selectedNewDate={selectedNewDate}
              selectedDateObject={selectedDateObject}
              currentSession={currentSession}
            />
          </>
        )}
        {isFollowUp && (
          <>
            <Wrapper>
              <InputWrapper
                className="input-wrapper"
                style={{ height: insight && 'auto' }}
              >
                <Left>
                  <FormLabel
                    htmlFor="insight"
                    className={insight ? 'input-label show' : 'input-label'}
                  >
                    Add a message here
                  </FormLabel>
                  <FormText
                    id="insight"
                    name="insight"
                    value={insight}
                    type="text"
                    rows="1"
                    // ref={diaRef}
                    onChange={(e) => {
                      setInsight(e.target.value)
                      // handleKeyUp(e, diaRef)
                    }}
                    placeholder="Add a message here"
                    className={
                      insight ? 'textarea-value active' : 'textarea-value'
                    }
                  ></FormText>
                </Left>
              </InputWrapper>
            </Wrapper>
          </>
        )}
        <RightBottom>
          <ButtonLeft>
            <ButtonTypeOne
              text="Cancel"
              color={THEME_COLORS.chips_blue_on_container_2}
              bkgcolor={THEME_COLORS.white}
              border="none"
              onclick={handleCancel}
            />
          </ButtonLeft>
          <ButtonRight>
            {activeOption !== 1 && (
              <ButtonTypeOne
                text="Previous"
                color={THEME_COLORS.dark_500}
                bkgcolor={THEME_COLORS.white}
                border={THEME_COLORS.light_400}
                onclick={handlePrevious}
              />
            )}
            <ButtonTypeOne
              text={activeOption !== 3 ? 'Next' : 'Submit'}
              ml="10px"
              color={THEME_COLORS.white}
              bkgcolor={THEME_COLORS.chips_blue_on_container_2}
              onclick={handleFormSubmit}
            />
          </ButtonRight>
        </RightBottom>
      </Container>
    </>
  )
}

export default FollowUp
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 30px; */
`

const FormTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 5px;
`
const Top = styled.div`
  margin-top: 20px;
  input {
    margin-right: 10px;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 0.5em;
      border: 2px solid
        ${(props) => (props.checked ? 'none' : THEME_COLORS.light_400)};
      background-color: ${(props) =>
        props.checked
          ? THEME_COLORS.chips_blue_on_container_2
          : THEME_COLORS.white};
      background-image: url(${(props) => props.check});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`
const Description = styled.p`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
`
const Bottom = styled.p`
  color: #3e3e53;
  font-size: 14px;
  margin: 20px 0;
  span {
    color: ${THEME_COLORS.chips_blue_on_container_2};
  }
`
const ToggleEvent = styled.div`
  display: flex;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Left = styled.div`
  width: 100%;
`
const Right = styled.div``

const TitleSlot = styled.h3`
  color: ${THEME_COLORS.text_title};
  font-size: 16px;
  font-family: 'DM_sans_medium';
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`
const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`
const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`
const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${THEME_COLORS.light_400};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`
const ToggleInputChecked = styled(ToggleInput)`
  &:checked + ${ToggleSlider} {
    background-color: #366ee3;
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`
const Wrapper = styled.div`
  margin: 20px 0;
  /* padding-bottom: 40px;
  border-bottom: 1px solid #f5f5f5; */
`
const InputWrapper = styled.div`
  width: 65%;
`

const FormLabel = styled.label`
  display: none;
  &.show {
    display: block;
  }
`
const FormText = styled.textarea`
  &.active {
    font-weight: 500;
    &#symptoms {
      /* color: red; */
      font-weight: 600;
    }
  }
`
const Cover = styled.div`
  height: max-content;
`

const RightBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid #d3d3d3;

  @media all and (max-width: 980px) {
    margin-top: 50px;
  }
`
const ButtonLeft = styled.div``
const ButtonRight = styled.div`
  display: flex;
`
